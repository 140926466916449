"use client"

import { PropsWithChildren } from "react"
import { UseFormReturn } from "react-hook-form"
import { twMerge } from "tailwind-merge"

type Props = {
    form: UseFormReturn<any>
    disabled?: boolean
    className?: string
}

export default function FormSubmit(props: PropsWithChildren<Props>) {
    return (
        <div>
            <button
                type="submit"
                className={twMerge(
                    `flex w-full justify-center rounded-md bg-primary-600 px-3 py-2.5 text-sm
                    font-semibold leading-6 text-secondary-600 shadow-sm hover:bg-primary-500
                    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                    focus-visible:outline-primary-600`,
                    props.className,
                )}
                disabled={props.form.formState.isSubmitting || props.disabled}
            >
                {props.form.formState.isSubmitting ? "Loading" : props.children}
            </button>
        </div>
    )
}
