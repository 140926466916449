/* eslint @typescript-eslint/no-unsafe-argument: 0 */
/* eslint @typescript-eslint/no-unsafe-assignment: 0 */
/* eslint @typescript-eslint/no-unsafe-call: 0 */
/* eslint @typescript-eslint/no-unsafe-return: 0 */

import { zodResolver } from "@hookform/resolvers/zod"
import { useId } from "react"
import {
    FieldArray,
    FieldArrayPath,
    FieldPath,
    FieldValues,
    FormProvider,
    GlobalError,
    PathValue,
    SubmitHandler,
    UseFormProps,
    UseFormRegister,
    UseFormReturn,
    useForm,
} from "react-hook-form"
import { z } from "zod"
import { useToast } from "~/app/_hooks/useToast"
import deepGet from "~/shared/deepGet"

export type UseZodFormRegister<TInput extends FieldValues> =
    UseFormRegister<TInput>

type PayloadFieldArrayFn<
    TFieldValues extends FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues>,
> = (
    value:
        | FieldArray<TFieldValues, TFieldArrayName>
        | FieldArray<TFieldValues, TFieldArrayName>[],
) => void

type IndexFieldArrayFn = (index: number) => void

type ValuesFieldArrayFn<
    TFieldValues extends FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues>,
> = () => PathValue<TFieldValues, TFieldArrayName>

type UseFormFields<
    TFieldValues extends FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues>,
> = {
    append: PayloadFieldArrayFn<TFieldValues, TFieldArrayName>
    remove: IndexFieldArrayFn
    values: ValuesFieldArrayFn<TFieldValues, TFieldArrayName>
}

type FieldsFn<TFieldValues extends FieldValues> = <
    TFieldArrayName extends FieldArrayPath<TFieldValues>,
>(
    path: TFieldArrayName,
) => UseFormFields<TFieldValues, TFieldArrayName>

export type UseZodForm<TFieldValues extends FieldValues> =
    UseFormReturn<TFieldValues> & {
        id: string
        fields: FieldsFn<TFieldValues>
        error: <TPath extends FieldPath<TFieldValues>>(
            path: TPath,
        ) => GlobalError
    }

export function useZodForm<TSchema extends z.ZodType>(
    props: Omit<UseFormProps<TSchema["_input"]>, "resolver"> & {
        schema: TSchema
    },
) {
    const form = useForm<TSchema["_input"]>({
        ...props,
        resolver: zodResolver(props.schema, undefined, {
            // This makes it so we can use `.transform()`s on the schema
            // without same transform getting applied
            // again when it reaches the server
            raw: true,
        }),
    }) as UseZodForm<TSchema["_input"]>

    form.id = useId()
    const myFields = (path: any) => ({
        append: (value: any) => {
            const values = form.getValues(path)
            form.setValue(path, [...values, value] as any)
        },
        remove: (index: number) => {
            const values = form.getValues(path)
            values.splice(index, 1)
            form.setValue(path, values)
        },
        values: () => form.getValues(path),
    })
    form.fields = myFields as unknown as FieldsFn<TSchema["_input"]>
    form.error = (path: any) => {
        return deepGet(form.formState.errors, path) ?? {}
    }

    return form
}

export type AnyZodForm = UseZodForm<any>

export function ZodForm<TInput extends FieldValues>(
    props: Omit<React.ComponentProps<"form">, "onSubmit" | "id"> & {
        handleSubmit: SubmitHandler<TInput>
        form: UseZodForm<TInput>
        className?: string
    },
) {
    const { handleSubmit, form, ...passThrough }: typeof props = props
    const toast = useToast()
    return (
        <FormProvider {...form}>
            <form
                {...passThrough}
                id={form.id}
                className={props.className}
                onSubmit={(event) => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    form.handleSubmit(async (values) => {
                        try {
                            await handleSubmit(values)
                        } catch (cause: any) {
                            toast({
                                type: "error",
                                message: "Something went wrong.",
                            })
                            /* form.setError("root.server", {
                                message:
                                    (cause as Error)?.message ??
                                    "Unknown error",
                                type: "server",
                            }) */
                        }
                    })(event)
                }}
            />
        </FormProvider>
    )
}
